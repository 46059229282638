import { Asset } from '@/util/payload/types'
import Image, { ImageProps } from 'next/image'
import { FunctionComponent } from 'react'

export interface AssetImageProps extends Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'> {
  width?: number | string
  height?: number | string
  asset?: Asset | number
}

export const AssetImage: FunctionComponent<AssetImageProps> = ({ asset, width = '100%', height = 'auto', ...props }) => {
  if (!asset || typeof asset === 'number') { return <></> }
  return <Image src={asset.url!} alt={asset.title} width={asset.width ?? 0} height={asset.height ?? 0} sizes="100vw" style={{
    width: width === 'auto' ? undefined : width,
    height
  }} {...props} />
}
