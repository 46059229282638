'use client'

import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { RichText, hasRichText } from '@/components/RichText/RichText'
import { FeatureListSectionProps } from '@/util/payload/types'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { ActionBlock } from '../ActionBlock/ActionBlock'
import { FeatureListItem } from './FeatureListItem'

export const FeatureListSectionBlock: FunctionComponent<FeatureListSectionProps> = ({ tagline, title, description, actions, items, columns }) => {
  return (
    <section className="py-12 md:py-16 lg:py-20 xl:py-24">
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        <MotionDiv preset='fade-up' className="mx-auto text-center">
          {tagline && (
            <div className="mb-4 inline-block rounded-full bg-zumthor px-4.5 py-1.5 dark:border dark:border-strokedark dark:bg-blacksection">
              <span className="text-sectiontitle font-medium text-black dark:text-white">
                {tagline}
              </span>
            </div>
          )}
          <h2 className="mb-4 text-3xl font-bold text-black dark:text-white xl:text-hero text-center">
            {title}
          </h2>
          {hasRichText(description) && (
            <div className='mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]'>
              <RichText content={description} />
            </div>
          )}
        </MotionDiv>
        {(actions && actions.length > 0) && (
          <div className="mt-4 flex items-center justify-center gap-4">
            {actions.map((props) => <ActionBlock key={props.id} {...props} />)}
          </div>
        )}
        {items && (
          <div className={clsx('mt-12.5 grid grid-cols-1 gap-7.5 md:grid-cols-2 lg:mt-15 xl:mt-20 xl:gap-12.5', columns ? `lg:grid-cols-${columns}` : 'lg:!grid-cols-3')}>
            {items.map((item) => <FeatureListItem key={item.id} {...item} />)}
          </div>
        )}
      </div>
    </section>
  )
}
