import { AssetImage } from '@/components/AssetImage/AssetImage'
import { Asset } from '@/util/payload/types'
import { FunctionComponent } from 'react'

export interface TestimonialItemBlockProps {
  id: string
  name: string
  designation: string
  image?: Asset | number | null
  content: string
  isLivePreview?: boolean
}

export const TestimonialItemBlock: FunctionComponent<TestimonialItemBlockProps> = ({ name, designation, image, content, isLivePreview }) => {
  return (
    <div className="rounded-lg bg-white p-9 pt-7.5 shadow-solid-9 dark:border dark:border-strokedark dark:bg-blacksection dark:shadow-none">
      <div className="mb-7.5 flex justify-between border-b border-stroke pb-6 dark:border-strokedark">
        <div>
          <h3 className="mb-1.5 text-metatitle3 text-black dark:text-white">{name}</h3>
          <p>{designation}</p>
        </div>
        {image && (
          <AssetImage width={60} height={60} className="rounded-full" asset={image} />
        )}
      </div>
      <p>{content}</p>
    </div>
  )
}