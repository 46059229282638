'use client'

import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { ShapeDotted } from '@/components/Svg/ShapeDotted'
import { FeatureTabSectionProps } from '@/util/payload/types'
import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'
import { FeatureTabItemBlock } from './FeatureTabItemBlock'

export const FeatureTabSectionBlock: FunctionComponent<FeatureTabSectionProps> = ({ items }) => {
  const [currentTab, setCurrentTab] = useState(items?.[0]?.id ?? '')

  return (
    <section className="relative pb-20 pt-18.5 lg:pb-22.5">
      <div className="relative mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
        <div className="absolute -top-16 -z-1 mx-auto h-[350px] w-[90%]"><ShapeDotted /></div>

        {(items && items.length > 0) && (
          <MotionDiv preset='fade-up' className="mb-15 flex flex-wrap justify-center rounded-[10px] border border-stroke bg-white shadow-solid-5 dark:border-strokedark dark:bg-blacksection dark:shadow-solid-6 md:flex-nowrap md:items-center lg:gap-7.5 xl:mb-21.5 xl:gap-12.5">
            {items.map((tab, index) => (
              <div key={tab.id} onClick={() => setCurrentTab(tab.id ?? `${index}`)} className={clsx(
                'relative flex w-full cursor-pointer items-center gap-4 border-b border-stroke px-6 py-2 last:border-0 dark:border-strokedark md:w-auto md:border-0 xl:px-13.5 xl:py-5',
                (currentTab === tab.id || currentTab === `${index}`) && 'active before:absolute before:bottom-0 before:left-0 before:h-1 before:w-full before:rounded-tl-[4px] before:rounded-tr-[4px] before:bg-primary'
              )}>
                <div className="flex h-12.5 w-12.5 items-center justify-center rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection">
                  <p className="text-metatitle3 font-medium text-black dark:text-white">
                    {index + 1}
                  </p>
                </div>
                <div className="md:w-3/5 lg:w-auto">
                  <button className="text-sm font-medium text-black dark:text-white xl:text-regular">
                    {tab.tab}
                  </button>
                </div>
              </div>
            ))}
          </MotionDiv>
        )}

        {/* content */}
        {(items && items.length > 0) && (
          <MotionDiv preset='fade-up' className="mx-auto max-w-c-1154">
            {items.map((item) => (
              <div key={item.id} className={item.id === currentTab ? 'block' : 'hidden'}>
                <FeatureTabItemBlock {...item} />
              </div>
            ))}
          </MotionDiv>
        )}
      </div>
    </section>
  )
}