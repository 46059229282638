'use client'
import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { hasRichText, RichText } from '@/components/RichText/RichText'
import { TestimonialSectionProps } from '@/util/payload/types'
import { FunctionComponent } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TestimonialItemBlock } from './TestimonialItemBlock'

export const TestimonialSectionBlock: FunctionComponent<TestimonialSectionProps> = ({ title, tag, testimonials, description }) => {
  return (
    <section>
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        <MotionDiv preset='fade-up' className="mx-auto text-center">
          {tag && (
            <div className="mb-4 inline-block rounded-full bg-zumthor px-4.5 py-1.5 dark:border dark:border-strokedark dark:bg-blacksection">
              <span className="text-sectiontitle font-medium text-black dark:text-white">
                {tag}
              </span>
            </div>
          )}
          <h2 className="mx-auto mb-4 text-3xl font-bold text-black dark:text-white md:w-4/5 xl:w-1/2 xl:text-sectiontitle3">
            {title}
          </h2>
          {hasRichText(description) && (
            <div className='mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]'>
              <RichText content={description} />
            </div>
          )}
        </MotionDiv>
      </div>
      <MotionDiv preset='fade-up' className="mx-auto mt-15 max-w-c-1235 px-4 md:px-8 xl:mt-20 xl:px-0">
        <div className="swiper testimonial-01 mb-20 pb-22.5">
          <Swiper spaceBetween={50} slidesPerView={2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            breakpoints={{ 0: { slidesPerView: 1 }, 768: { slidesPerView: 2 } }}>
            {testimonials.map((item) => (
              <SwiperSlide key={item.id}>
                <TestimonialItemBlock id={item?.id ?? ''} content={item.testimonial} designation={item.company} image={item.image} name={item.name} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </MotionDiv>
    </section>
  )
}