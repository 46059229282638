import { RichText } from '@/components/RichText/RichText'
import { FeatureTabItemProps } from '@/util/payload/types'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { AssetImage } from '../../AssetImage/AssetImage'

export const FeatureTabItemBlock: FunctionComponent<FeatureTabItemProps> = ({ title, image, description, imagePosition }) => {
  return (
    <div className={clsx('flex items-center gap-8 lg:gap-19 flex-col md:flex-row', imagePosition === 'left' ? 'flex-row-reverse' : '')}>
      <div className="md:w-1/2">
        <h2 className="mb-7 text-2xl font-bold text-black dark:text-white xl:text-4xl">{title}</h2>
        <RichText content={description} />
      </div>
      <div className="relative mx-auto aspect-[562/366] max-w-[550px] w-full md:w-1/2">
        <AssetImage asset={image} />
      </div>
    </div>
  )
}
