'use client'

import { Icon } from '@/components/Icon/Icon'
import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { RichText } from '@/components/RichText/RichText'
import { ShapeDotted } from '@/components/Svg/ShapeDotted'
import { FAQSectionProps } from '@/util/payload/types'
import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'
import { ActionBlock } from '../ActionBlock/ActionBlock'

export const FAQSectionBlock: FunctionComponent<FAQSectionProps> = ({ title, tagline, type, actions, questions }) => {
  const [activeFaq, setActiveFaq] = useState<string>(questions?.[0]?.question ?? '')
  return (
    <section className="overflow-hidden py-12 md:py-16 lg:py-20 xl:py-24">
      <div className="relative mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0">
        <div className="absolute -bottom-16 -z-1 h-full w-full">
          <ShapeDotted />
        </div>
        <div className={clsx(
          'flex flex-wrap gap-8 md:flex-nowrap md:items-center xl:gap-32.5',
          type === 'left' ? 'flex-row-reverse' : ''
        )}>
          <MotionDiv preset='fade-left' className="md:w-1/4">
            {tagline && (<span className="font-medium uppercase text-black dark:text-white">{tagline}</span>)}
            <h2 className="relative mb-6 text-3xl font-bold text-black dark:text-white xl:text-hero">
              {title}
            </h2>
            {!!actions?.length && (<ActionBlock {...actions[0]} />)}
          </MotionDiv>
          {!!questions?.length && (
            <MotionDiv preset='fade-right' className="w-full md:w-3/4">
              <div className="rounded-lg bg-white shadow-solid-8 dark:border dark:border-strokedark dark:bg-blacksection">
                {questions.map(({ question, answer }) => (
                  <div key={question} className="flex flex-col border-b border-stroke last-of-type:border-none dark:border-strokedark">
                    <button className="flex text-start cursor-pointer items-center justify-between px-6 py-5 text-metatitle3 font-medium text-black dark:text-white lg:px-9 lg:py-7.5"
                      onClick={() => { setActiveFaq(question) }}>
                      {question}
                      <Icon icon={(question === activeFaq) ? 'MinusIcon' : 'PlusIcon'} className='currentColor' width={24} height={24} />
                    </button>
                    <div className={clsx(
                      'border-t text-start border-stroke px-6 py-5 dark:border-strokedark lg:px-9 lg:py-7.5',
                      (question === activeFaq) ? 'block' : 'hidden'
                    )}><RichText content={answer} /></div>
                  </div>
                ))}
              </div>
            </MotionDiv>
          )}
        </div>
      </div>
    </section>
  )
}
