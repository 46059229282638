'use client'

import { motion, MotionProps } from 'framer-motion'
import { FunctionComponent } from 'react'

export type MotionPreset = 'fade-up' | 'fade-down' | 'fade-left' | 'fade-right'

export const PresetDefaults: MotionProps = {
  initial: 'hidden',
  whileInView: 'visible',
  transition: { duration: 0.5, delay: 0.1 },
  viewport: { once: true }
}

export const Presets: Record<MotionPreset, MotionProps> = {
  'fade-up': { ...PresetDefaults, variants: { hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0 } } },
  'fade-down': { ...PresetDefaults, variants: { hidden: { opacity: 0, y: +20 }, visible: { opacity: 1, y: 0 } } },
  'fade-left': { ...PresetDefaults, variants: { hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } } },
  'fade-right': { ...PresetDefaults, variants: { hidden: { opacity: 0, x: +20 }, visible: { opacity: 1, x: 0 } } }
}

export interface MotionDivProps extends MotionProps {
  preset: MotionPreset
}

export const MotionDiv: FunctionComponent<MotionDivProps> = ({ preset, children, ...props }) => {
  const presetProps = Presets[preset]
  return (<motion.div {...presetProps} {...props}>{children}</motion.div>)
}
