import { ActionProps, Page } from '@/util/payload/types'
import { ColorVariant } from '@/util/theme'
import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent } from 'react'

export const ActionBlock: FunctionComponent<ActionProps> = ({ title, type, url, page, variant, target }) => {
  const href = type === 'url' ? url : (page as Page)?.slug

  const colorVariants: Record<ColorVariant, string> = {
    neutral: 'bg-neutral dark:bg-neutral-dark hover:bg-neutral-hover dark:hover:bg-neutral-hover-dark text-white',
    emphasis: 'bg-emphasis dark:bg-emphasis-dark hover:bg-emphasis-hover dark:hover:bg-emphasis-hover-dark text-white dark:text-black',
    primary: 'bg-primary dark:bg-primary-dark hover:bg-primary-hover dark:hover:bg-primary-hover-dark text-white',
    secondary: 'bg-secondary dark:bg-secondary-dark hover:bg-secondary-hover dark:hover:bg-secondary-hover-dark text-white',
    text: 'inline-block hover:text-primary'
  }

  return (
    <Link href={href ?? '/'} target={target ?? '_top'} aria-label={`${title} button`}
      className={clsx(
        variant !== 'text' && 'inline-block rounded-full px-7.5 py-2.5 duration-300 ease-in-out text-nowrap whitespace-nowrap',
        colorVariants[variant]
      )}>
      {title}
    </Link>
  )
}
