import { CollectionSlug, DataFromCollectionSlug } from 'payload'
import { useEffect, useState } from 'react'

export function useRelations<
  S extends CollectionSlug,
  T extends DataFromCollectionSlug<S>
>(collectionSlug: S, records: (number | T)[]): T[] {
  const [results, setResults] = useState<T[]>(() => {
    if (!records || records.length > 0 && typeof records[0] !== 'number') {
      return records as T[]
    } else {
      return []
    }
  })
  useEffect(() => {
    if (!records || records.length === 0) {
      setResults([])
    } else if (typeof records[0] === 'number') {
      fetch(`${process.env.NEXT_PUBLIC_URL}/api/${collectionSlug}?where[id][in]=${records.join(',')}`, {
        method: 'GET'
      }).then((response) => {
        return response.json()
      }).then(({ docs }) => {
        setResults(records.map((id) => docs.find((doc: T) => doc.id === id)))
      })
    }
  }, [records, collectionSlug])
  return results
}
