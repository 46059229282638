'use client'

import { useRelations } from '@/hooks/useRelations'
import { BrandSectionProps } from '@/util/payload/types'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { MotionDiv } from '../../MotionDiv/MotionDiv'

export const BrandSectionBlock: FunctionComponent<BrandSectionProps> = (props) => {
  const items = useRelations('Asset', props.items)
  return (
    <section className="border border-x-0 border-y-stroke bg-alabaster py-11 dark:border-y-strokedark dark:bg-black">
      {props.title && (<h2 className="text-center mb-4 lg:mb-10 text-2xl font-bold text-black dark:text-white w-full xl:text-sectiontitle2">{props.title}</h2>)}
      <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
        <div className={`grid grid-cols-3 items-center justify-center gap-7.5 md:grid-cols-${items.length < 6 ? items.length : 6} lg:gap-12.5 xl:gap-29`}>
          {items?.map((item) => (
            <MotionDiv key={item.id} preset='fade-up' className="mx-auto relative flex items-center justify-center h-10 w-[98px]">
              <Image className="opacity-65 transition-all w-full duration-300 hover:opacity-100" src={item.url!} alt={item.title} width={0} height={0} sizes='100vw' />
            </MotionDiv>
          ))}
        </div>
      </div>
    </section>
  )
}
