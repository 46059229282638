import clsx from 'clsx'
import { FunctionComponent, SVGAttributes } from 'react'
import { IconName, Icons } from '../../util/icons'

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  icon: IconName | string
}

export const Icon: FunctionComponent<IconProps> = ({ icon, className, ...rest }) => {
  const IconComponent = Icons[icon as IconName]
  return <IconComponent className={clsx('block', className)} {...rest} />
}
