import { BulletListProps } from '@/util/payload/types'
import { FunctionComponent } from 'react'

export const BulletListBlock: FunctionComponent<BulletListProps> = ({ bullets }) => {
  if (!bullets) { return <></> }
  return (
    <div className='flex flex-col mt-6 gap-6'>
      {bullets.map(({ id, title, description }, index) => (
        <div key={id} className="flex items-center gap-5">
          <div className="flex h-12 w-12 items-center justify-center rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection">
            <p className="text-metatitle font-semibold text-black dark:text-white">{index + 1}</p>
          </div>
          <div className="w-3/4">
            <h3 className="mb-0.5 text-metatitle2 text-black dark:text-white">{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
